<template>
    <div>
        <!-- LOADINGBAR? -->
        <slot />
    </div>
</template>

<script setup>
import { useFullscreen, onKeyStroke } from '@vueuse/core';

const { toggle } = useFullscreen(document.body);

onKeyStroke('f', (e) => {
    e.preventDefault();
    toggle();
});
</script>
